import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { useAuth } from 'hooks/useAuth';
import { Help } from "components/help";
import logo from "assets/logos/Logo_Dielsa_White_C.png";
import fb from "assets/img/Dielsa/Icon_Facebook.svg";
import twt from "assets/img/Dielsa/Icon_Twitter.svg";
import ig from "assets/img/Dielsa/Icon_Instagram.svg";
import li from "assets/img/Dielsa/Icon_LinkedIn.svg";
import yt from "assets/img/Dielsa/Icon_Youtube.svg";
import iso from "assets/img/Dielsa/logo_iso.svg"
import { homeServices } from "../../_services/home.services";
import { accountService } from 'services/account/account.service';
const FooterNew = () => {
    const { isLogged,getUserData } = useAuth();
    const dataUser = getUserData()
    const [paginasInstitucionales, setPaginasInstitucionales] = useState([]);

    const goTo = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const getInstPages = async () => {
        let _ip = await homeServices.getInstitutionalPages();
        let a = [];
        for (let i = 0; i < _ip.paginas_institucionales.length; i++) {
          if (_ip.paginas_institucionales[i].pi_nombre_seccion !== "Error 404") {
            a.push(_ip.paginas_institucionales[i]);
          }
        }
        setPaginasInstitucionales(a);
      };
      
  useEffect(() => {
    getInstPages();
    if (isLogged)
        accountService.addInteractions({
          type: 2,
          userId: dataUser.usuario.snu_usuario_snu_id,
        });
  }, []);


 

    return (
        <Box
            sx={{
                backgroundColor: '#0b3196',
                color: '#fff',
                paddingTop: 4,
                paddingBottom: 2,
            }}
        >
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={3} md={3} lg={3} sx={{ padding: '2px 4px' }}>
                        <Box>
                            <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                Cónocenos
                            </Typography>
                          
                            {paginasInstitucionales.map((page) =>
            page.pi_nombre_seccion !== "Marcas" &&
            page.pi_nombre_seccion !== "Blog" ? (
                <Box>
                     <Typography
                                    variant="body2"
                                    gutterBottom
                                    component={Link}
                                    to={`/institucional/${page.pi_nombre_seccion}`}
                                    sx={{
                                        color: '#fff',
                                        '&:hover': {
                                            color: '#8f8f8f',
                                        }
                                    }}
                                >
                                    {page.pi_nombre_seccion}

                                </Typography>
                   
                 
                
               </Box>
            ) : (
              ""
            )
          )}




                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} sx={{ padding: '2px 4px' }}>
                        <Box>
                            <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                Asistencia
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={Link}
                                to={'/contact'}
                                sx={{
                                    color: '#fff',
                                    '&:hover': {
                                        color: '#8f8f8f',
                                    }
                                }}
                            >
                                Servicio al cliente
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <Help />
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={Link}
                                to={'/faq'}
                                sx={{
                                    color: '#fff',
                                    '&:hover': {
                                        color: '#8f8f8f',
                                    }
                                }}
                            >
                                Preguntas frecuentes
                            </Typography>
                        </Box>
                    </Grid>
                    {!isLogged && <Grid item xs={12} sm={2} md={2} lg={2} sx={{ padding: '2px 4px' }}>
                        <Box>
                            <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                Alta como cliente
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={Link}
                                to={'/register'}
                                sx={{
                                    color: '#fff',
                                    '&:hover': {
                                        color: '#8f8f8f',
                                    }
                                }}
                            >
                                Llenar formulario
                            </Typography>
                        </Box>
                    </Grid>}
                    <Grid item xs={12} sm={3} md={3} lg={3} sx={{ padding: '2px 4px' }}>
                        <Box>
                            <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                Métodos de pago
                            </Typography>
                            {/* <Typography variant="body2" gutterBottom>
                                Tarjeta de crédito y débito
                            </Typography> */}
                            <Typography variant="body2" gutterBottom>
                                Transferencias
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Crédito Dielsa
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} sx={{ padding: '2px 4px' }}>
                        <Box>
                            <Typography variant="button" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                Formas de envío
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Envío express
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Envío nacionales
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={3} md={3} lg={3} sx={{
                                padding: '3rem 0rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box component={Link} to={'/'}>
                                <img
                                    src={logo}
                                    style={{
                                        maxWidth: 185,
                                        maxHeight: 70,
                                    }}
                                />
                            </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}
                            sx={{
                                padding: '3rem 1rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                            <Box>
                                <Typography variant="body2" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                    {'Contáctamos: (81) 8123 9359'}
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                    contacto@dielsa.com
                                </Typography>
                                <Typography variant="body2" display="block" gutterBottom sx={{ fontWeight: 700 }}>
                                    Lunes - Viernes: 8:30 am - 6:30 pm
                                </Typography>
                            </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}
                            sx={{
                                padding: '3rem 1rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <IconButton
                                    aria-label="fb"
                                    sx={{ padding: '3px' }}
                                    onClick={() => goTo("https://www.facebook.com/Dielsamsi/")}
                                >
                                    <img
                                        src={fb}
                                        width={38}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="twt"
                                    sx={{ padding: '3px' }}
                                    onClick={() => goTo("https://twitter.com/Dielsamsi")}
                                >
                                    <img
                                        src={twt}
                                        width={38}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="ig"
                                    sx={{ padding: '3px' }}
                                    onClick={() => goTo("https://www.instagram.com/dielsamsi/?hl=es")}
                                >
                                    <img
                                        src={ig}
                                        width={38}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="li"
                                    sx={{ padding: '3px' }}
                                    onClick={() => goTo("https://www.linkedin.com/company/dielsa-telecomunicaciones/")}
                                >
                                    <img
                                        src={li}
                                        width={38}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="yt"
                                    sx={{ padding: '3px' }}
                                    onClick={() => goTo("https://www.youtube.com/channel/UCLYgOWzTRXXuJvMIcvB4GLw")}
                                >
                                    <img
                                        src={yt}
                                        width={38}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </IconButton>
                            </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3} sx={{ padding: '2px 4px' }}>
                        <a href={'/Certificado_iso_9000_5000.pdf'}>
                        <img
                                        src={iso}
                                        width={130}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                            </a>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default FooterNew;